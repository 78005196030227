import React from 'react';

const Stats = () => {
  return (
    <div className="w-full  p-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
       <div className="bg-secondary border border-white/10 p-6 rounded-sm shadow-lg text-center">
        <h3 className="text-lg font-semibold text-gray-300">Years of Experience</h3>
        <p className="text-4xl font-bold text-purple-600">4+</p>
      </div>
      <div className="bg-secondary border border-white/10 p-6 rounded-sm shadow-lg text-center">
      <h3 className="text-lg font-semibold text-gray-300">Number of Clients</h3>
        <p className="text-4xl font-bold text-blue-600">130+</p>
      </div>
      <div className="bg-secondary border border-white/10 p-6 rounded-sm shadow-lg text-center">
      <h3 className="text-lg font-semibold text-gray-300">Contributed Visits</h3>
        <p className="text-4xl font-bold text-green-600">735,000+</p>
      </div>

    </div>
  );
};

export default Stats;
