

function Header(){
    
    return(
        <div className="fixed z-30 flex min-h-[78px] w-full border-b border-white/5 bg-black/10 py-2 backdrop-blur-xl ">
            <div className="mx-auto flex w-full max-w-7xl items-center px-6">

                <a href="/" className="relative -top-0.5 mx-auto md:mx-0 md:hidden xl:block  text-2xl font-semibold text-white">Chrisbase12</a >

                <div className="relative mx-auto ml-0 hidden gap-4 px-4 md:flex xl:ml-auto xl:px-0">
                <a href="/" className="text-white/60 tracking-tighter">Home</a>
                <a  href="/experience" className="text-white/60 tracking-tighter">Experience</a>
                <a  href="/" className="text-white/60 tracking-tighter">Testimonials</a>
                </div>


            </div>
        </div>
    );
}
export default Header;