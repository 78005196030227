import React, { useState } from 'react';
import Header from 'src/components/header';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import BuildingExperience from '../Experience_building.json';
import ScriptingExperience from '../Experience_scripting.json';
import Experience from 'src/components/experienceTemplate';

const ExperiencePage = () => {
  const [activeJob, setActiveJob] = useState<string>('building');

  const handleToggleChange = (value: string) => {
    if (value !== activeJob) {
      setActiveJob(value); // Only change if the new value is different
    }
  };

  // Helper function to render experience items
  const renderExperiences = (data: { Title: string; content: string }[]) => {
    return data.map((item, index) => (
      <Experience key={index} Title={item.Title} content={item.content} />
    ));
  };

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <div className="flex flex-col justify-center items-center relative pt-[78px]">
        <h1 className="block font-axe text-white pt-4 uppercase text-5xl xs:text-7xl">
          <span className="text-accent">
            <span className="text-6xl xs:text-[83px]">P</span>ersonal
          </span>{' '}
          Experienc
          <span className="text-6xl xs:text-[83px]">e</span>
        </h1>
        <div className="flex bg-primary p-2 rounded m-4">
          <ToggleGroup.Root
            type="single"
            value={activeJob}
            onValueChange={handleToggleChange}
            aria-label="Job Toggle Group"
            className="flex space-x-4"
          >
            <ToggleGroup.Item
              value="building"
              aria-label="Building Jobs"
              className={`px-4 py-2 rounded cursor-pointer border-black/10 border-b-[4px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px] ${
                activeJob === 'building' ? 'bg-blue-500 text-white' : 'bg-primary text-gray-500'
              }`}
            >
              Building Jobs
            </ToggleGroup.Item>
            <ToggleGroup.Item
              value="scripting"
              aria-label="Scripting Jobs"
              className={`px-4 py-2 rounded cursor-pointer border-black/10 border-b-[4px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px] ${
                activeJob === 'scripting' ? 'bg-blue-500 text-white' : 'bg-primary text-gray-500'
              }`}
            >
              Scripting Jobs
            </ToggleGroup.Item>
          </ToggleGroup.Root>
        </div>
        <div className="mt-4 w-full max-w-2xl grid flex-grow gap-1 grid-cols-[repeat(auto-fill,minmax(12rem,1fr))]">
          {activeJob === 'building'
            ? renderExperiences(BuildingExperience)
            : renderExperiences(ScriptingExperience)}
        </div>
      </div>
    </div>
  );
};

export default ExperiencePage;
