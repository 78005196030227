interface ExperienceProps {
    Title: string;
    content: string;
  }

  const Experience: React.FC<ExperienceProps> = ({ Title, content}) => {
    return (
      <a className="relative size-full flex flex-col rounded-md justify-center items-center group gap-3 bg-secondary pt-3 px-2 pb-4 flex-1">
         <img
          src={content}
          className="rounded"
        />
        <h1 className="bg-black/20 text-sm flex justify-center text-gray-300 items-center rounded-md font-bold py-1.5 px-3">{Title}</h1>
       
      </a>
    );
  };
  
  export default Experience;