import React from 'react';
import { Scroll,Hammer } from 'lucide-react';

const AboutMe = () => {
  return (
    <section className="bg-background pb-12 px-6 mx-auto text-center">
      <div className="max-w-6xl mx-auto text-center">
        <h2 className="text-3xl font-bold text-gray-300">About Me</h2>
        <p className="mt-4 text-lg text-gray-400">
          Hi, I'm a passionate Roblox developer with expertise in scripting, building, and creating immersive experiences.
          I specialize in delivering high-quality, optimized, and engaging projects that bring your ideas to life.
          With a deep understanding of both technical scripting and aesthetic building, I ensure your vision is fully realized while saving you{' '}
          <span className="inline-flex items-center align-middle">
            <img
              src="/robux.png"
              alt="robux"
              className="w-6 h-6 mr-1 relative top-[-.1em]"
            /> 
          </span>.
        </p>
      </div>

      <div className="max-w-6xl mx-auto text-center flex justify-center items-center gap-4 my-4">
        <a className='flex flex-col cursor-pointer bg-accent min-h-28 w-64 px-4 rounded-sm border border-black/40 border-b-[6px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px]'>
          <div className='flex justify-between  py-1'>
            <h4 className='text-white/80 tracking-tighter'>.01</h4>
            <Scroll strokeWidth={1.15} className='w-20 h-20 text-gray-300 ' />

          </div>
          <p></p>
          <h3 className="text-white text-3xl font-semibold uppercase tracking-tighter mt-2 text-left">Scripting</h3>       
        </a>
        <hr className='w-[1px] bg-white/20 h-20'></hr>
        <a className='flex flex-col cursor-pointer bg-accent min-h-28 w-64 px-4 rounded-sm border border-black/40 border-b-[6px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px]'>
          <div className='flex justify-between  py-1'>
            <h4 className='text-white/80 tracking-tighter'>.02</h4>
            <Hammer strokeWidth={1.15} className='w-20 h-20 text-gray-300 ' />

          </div>
          <p></p>
          <h3 className="text-white text-3xl font-semibold uppercase tracking-tighter mt-2 text-left">Building</h3>       
        </a>
      </div>
      <h3 className='text-white/50 font-semibold leading-tight tracking-tighter text-xs '>Check out my prior work</h3>
    </section>
  );
};

export default AboutMe;
