import AboutMe from "./components/aboutMe";
import Header from "./components/header";
import Stats from "./components/stats";
import HeroVouches from "./components/vouches-hero";

function App() {
  return (
    <div className="App">
      <Header />
      <div className="bg-background py-24 ">
     
        <div className="flex flex-1 relative min-h-screen w-full overflow-hidden pt-[78px] ">
          <div className="relative z-10 mx-auto flex w-full flex-col items-center p-6 text-center text-secondary/75 md:mt-16 lg:w-auto lg:p-16 lg:px-0">
            <h1 className="text-gray-300 text-8xl font-semibold font-montserrat">
              Hello! I'm <br /> <span className="text-white">Chrisbase12</span>
            </h1>
            <h3 className="block tracking-normal text-white/60 font-medium leading-snug uppercase mt-4 mb-2">
              Transforming Ideas into Reality
            </h3>
            <h3 className="block tracking-normal text-white/80 font-semibold text-lg leading-snug uppercase">
              Scripting & Building
            </h3>
            <div className="flex w-full flex-col gap-4 sm:mb-[87px] sm:w-auto sm:flex-row mt-14">
              <a href="https://discord.com/channels/@me/1312172934458249306" className="flex items-center gap-1 border border-black/40 py-2 px-4 rounded bg-accent text-white uppercase font-semibold text-md border-b-[4px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px]">
                <img src="/info.png" className="w-5" alt="" />
                Let's Talk!
              </a>
              <button className="flex items-center gap-1 border border-white/5 py-2 px-4 rounded bg-white/5 text-white/70 uppercase font-medium text-md border-b-[4px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px]">
                About Me
              </button>
            </div>
            <div className="animate-float p-1 border opacity-40 fill-white/50 rounded-full">
              <img className="" src="/arrow.svg" />
            </div>
          </div>

          <div className="pointer-events-none absolute bottom-0 left-0 h-screen w-full overflow-hidden">
           <div className="w-full h-full blur-[2px] opacity-30">
           <div className="absolute left-3/6 top-[20%] animate-upDown">
              <img className="transform scale-x-[-1]" src="https://tr.rbxcdn.com/180DAY-55de1dd89aa848cfa8f8a15505c7a7b6/420/420/Hat/Webp/noFilter" alt="Image 1" />
            </div>
            <div className="absolute left-2/3 top-[10%] animate-upDown">
              <img className="" src="https://tr.rbxcdn.com/180DAY-c6a34a07149df6be35113e44ad58214d/420/420/Hat/Webp/noFilter" alt="Image 1" />
            </div>
            <div className="absolute left-3/4 top-[50%] animate-upDown">
              <img className="" src="https://tr.rbxcdn.com/180DAY-e4178133c9a157f6ed04e1707d3e0f45/420/420/Hat/Webp/noFilter" alt="Image 2" />
            </div>

            <div className="absolute right-1/2 top-[50%] animate-upDown">
              <img className="" src="https://tr.rbxcdn.com/180DAY-5fe9d0858b3f37fdccb440e79627397d/420/420/Gear/Webp/noFilter" alt="Image 3" />
            </div>
           </div>

            <div className="animate-glimmer">
              <img src="/hero-lights.webp" className="object-cover object-center absolute bottom-0 left-0 h-full w-full" alt="" />
            </div>
            <div className="absolute bottom-0 z-20 w-full overflow-hidden pb-[30%] sm:pb-[20%] md:pb-[10%]">
              <img src="/hero-cracks.svg" className="object-cover object-center absolute left-0 top-0 hidden h-full w-full will-change-transform sm:block" alt="" />
            </div>
          </div>
        </div>
 
        <div className="py-24 relative z-10 mx-auto flex w-full max-w-7xl flex-col items-center px-6 xl:px-0">
          <h1 className="text-gray-300 text-3xl font-medium uppercase tracking-tighter ml-4">My Experience</h1>
          <Stats />
        </div>
        <AboutMe />
        <div className="">
          <HeroVouches />
        </div>
      </div>
    </div>
  );
}

export default App;
