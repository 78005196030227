import React, { useRef, useState } from 'react';
import Vouch from './vouch';
import vouchesData from '../vouches.json';

const HeroVouches: React.FC = () => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [showLeftBlur, setShowLeftBlur] = useState(false);
  const [showRightBlur, setShowRightBlur] = useState(true);

  let isDragging = false;
  let startX: number = 0;
  let scrollLeft: number = 0;

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!scrollContainerRef.current) return;
    isDragging = true;
    startX = e.pageX - scrollContainerRef.current.offsetLeft;
    scrollLeft = scrollContainerRef.current.scrollLeft;
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isDragging || !scrollContainerRef.current) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust scroll speed
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    updateBlur();
  };

  const handleMouseUpOrLeave = () => {
    isDragging = false;
  };

  const handleScroll = () => {
    updateBlur();
  };

  const updateBlur = () => {
    if (!scrollContainerRef.current) return;
    const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
    setShowLeftBlur(scrollLeft > 0);
    setShowRightBlur(scrollLeft + clientWidth < scrollWidth);
  };

  return (
    <div className="bg-white/5 py-6 px-4 rounded relative mx-auto w-full max-w-7xl">
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <img src="/warning.png" className="w-6" alt="Warning" />
          <h2 className="text-gray-300 tracking-normal text-lg font-medium leading-[1.3] uppercase">
            Vouches
          </h2>
        </div>
        <button className="px-4 py-1.5 text-gray-300 tracking-tighter text-sm font-medium leading-[1.3] uppercase bg-white/10 border border-white/20 rounded">
          View All
        </button>
      </div>

      <div className="relative mt-9 w-full overflow-hidden">
        {showLeftBlur && (
          <div className="absolute left-0 top-0 h-full w-12 bg-gradient-to-r from-[#1D1D21] to-transparent pointer-events-none"></div>
        )}

        {showRightBlur && (
          <div className="absolute right-0 top-0 h-full w-16 bg-gradient-to-l from-[#1D1D21] to-transparent pointer-events-none"></div>
        )}

        <div
          className="flex gap-4 overflow-x-auto no-scrollbar"
          ref={scrollContainerRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUpOrLeave}
          onMouseLeave={handleMouseUpOrLeave}
          onScroll={handleScroll}
          style={{ cursor: 'grab' }}
        >
          {vouchesData.map((vouch, index) => (
            <Vouch
              key={index}
              name={vouch.name}
              message={vouch.message}
              icon={vouch.icon}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HeroVouches;
