import React from 'react';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
  } from "./ui/tooltip"

interface VouchProps {
  name: string;
  message: string;
  icon: string;
}

const Vouch: React.FC<VouchProps> = ({ name, message, icon }) => {
  return (
    <div className="bg-[#313338] text-gray-300 p-4 rounded shadow mb-4 flex items-start gap-4 min-w-[280px] select-none">
      <img
        src={icon}
        alt={`${name}'s avatar`}
        className="w-12 h-12 rounded-full"
      />

      <div>
        <h3 className="flex items-center gap-2 text-base font-semibold text-[#00AFF4]">{name}
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger> <img src='/verify.png' className='w-4 '/></TooltipTrigger>
                <TooltipContent className='bg-gray-700 px-1 border-white/5'>
                <p className=''>Verified Vouch</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
            
           </h3>
        <p className="text-sm mt-1 text-gray-200">{message}</p>
      </div>
    </div>
  );
};

export default Vouch;
